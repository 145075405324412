@font-face {
  font-family: "lg";
  src: url("#{$lg-path-fonts}/lg.ttf?io9a6k") format("truetype"),
    url("#{$lg-path-fonts}/lg.woff?io9a6k") format("woff"),
    url("#{$lg-path-fonts}/lg.svg?io9a6k#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
