body,
html,
#root,
.main {
  height: 100%;
  min-width: 320px;
}

body {
  background-color: #f8f9fa;
  font-size: 14px;
}

.infinite-scroll-component {
  background-color: #f8f9fa;

  .card {
    box-shadow: none;
  }
}

.wrapper {
  overflow-y: auto;
}

.content {
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    margin-top: 70px !important;
  }
}

.min-width-100 {
  min-width: 100px;
}

.min-width-50 {
  min-width: 50px;
}

.min-width-25 {
  min-width: 25px;
}

.min-width-50-percent {
  min-width: 50%;
}

.min-height-50-percent {
  min-height: 50%;
}

.max-width-50 {
  max-width: 50px;
}

.max-width-100 {
  max-width: 100%;
}

.max-width-150 {
  max-width: 150px;
}

.max-height-50 {
  max-height: 50px;
}

.width-200 {
  width: 200px;
}

.navbar-custom {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  background: white;

  .navbar-container {
    max-width: 1400px;
    margin: 0 auto;
  }
}

.not-first-border-top:not(:first-child) {
  border-top: 1px solid #dee2e6 !important;
}

.ql-toolbar {
  border-bottom: 1px solid #dee2e6 !important;
  font-family: inherit;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

.ql-container {
  font-family: inherit;
  border: 0 !important;
}

.ql-editor.ql-blank::before {
  color: #98a6ad;
  font-style: normal;
}

.ql-formats {
  margin: 0 !important;
}

.min-height-150 {
  min-height: 150px;
}

.min-height-250 {
  min-height: 250px;
}

.min-height-100 {
  min-height: 100px;
}

.footer {
  left: 0;
  position: relative;
}

.relative {
  position: relative;
}

.text-transform-none {
  text-transform: none !important;
}

.bg-transparent {
  background: transparent;
}

.box-shadow-none {
  box-shadow: none;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-300 {
  font-weight: 300;
}

.letter-spacing-1p {
  letter-spacing: 1px;
}

.answer-quill {
  &:not(.active) {
    .ql-toolbar.ql-snow {
      height: 0;
    }
  }

  &.active {
    .ql-toolbar.ql-snow {
      height: 25px;
    }

    .ql-container.ql-snow {
      border-top: 1px solid #dee2e6 !important;
    }
  }

  .ql-toolbar.ql-snow {
    overflow: hidden;
    padding: 0 !important;
    border: none !important;

    -moz-transition: height 0.25s linear;
    -webkit-transition: height 0.25s linear;
    -o-transition: height 0.25s linear;
    transition: height 0.25s linear;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    height: 18px !important;
  }

  .ql-editor.ql-blank {
    padding-left: 7px !important;
  }

  .ql-editor.ql-blank::before {
    color: #6c757d;
    left: 7px !important;
  }
}

.question-user-name {
  @media (max-width: 376px) {
    max-width: 160px;
  }

  @media (min-width: 376px) {
    max-width: 220px;
  }

  @media (min-width: 576px) {
    max-width: unset;
  }
}

.question-title {
  border-color: #eef2f7 !important;
}

.question-content,
.answer-content {
  &.collapsed {
    position: relative;
  }

  overflow: hidden;

  p {
    margin-bottom: 0;
  }
}

.mt-25 {
  margin-top: 1.125rem !important;
}

.mr-05 {
  margin-right: 0.1875rem !important;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(241, 242, 242) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.box-sizing-content {
  box-sizing: content-box;
}

.simplebar-placeholder {
  display: none;
}

.categories-card {
  background: transparent;
  box-shadow: none;

  .card-header {
    background: transparent;
    border-bottom: 0;
  }

  .inbox-item {
    border-bottom: 0;
  }
}

.faded {
  position: absolute;
  bottom: 0px;
  display: block;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 80%
  );
}

.collapse-q {
  bottom: 0px;
  right: 0;
}

.height-300 {
  height: 300px;
}

.height-150 {
  height: 150px;
}

.height-128 {
  height: 128px;
}

.height-64 {
  height: 64px;
}

.height-48 {
  height: 48px;
}

.height-24 {
  height: 24px;
}

.light-gallery {
  margin: 0 -2px;
}

.light-gallery-question {
  height: 300px;
}

.light-gallery-answer {
  height: 64px;
}

.ql-formats:last-child {
  float: right;
}

.app-search {
  z-index: 0;
}

.text-underline {
  text-decoration: underline;
}

.opacity-0 {
  opacity: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.results-list {
  .card:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none !important;
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rounded-0 {
  border-radius: 0 !important;
}

.border-top-left-radius-0 {
  border-top-left-radius: 0 !important;
}

.border-top-right-radius-0 {
  border-top-right-radius: 0 !important;
}

.border-bottom-left-radius-0 {
  border-bottom-left-radius: 0 !important;
}

.border-bottom-right-radius-0 {
  border-bottom-right-radius: 0 !important;
}

.text-muted {
  color: #adb5bd !important;
}

.text-italic {
  font-style: italic;
}