@import "lg-variables";
@import "lg-mixins";
// Clearfix
.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

// lightgallery core

.lg-container {
  display: none;
  &.lg-show {
    display: block;
  }
}
.lg-on {
  scroll-behavior: unset;
}

// for idle state
.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  will-change: transform, opacity;
  @include transitionCustom(
    transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s
  );
}
// for idle state
.lg-show-in {
  .lg-toolbar,
  .lg-prev,
  .lg-next,
  .lg-pager-outer {
    opacity: 1;
  }
  &.lg-hide-sub-html {
    .lg-sub-html {
      opacity: 1;
    }
  }
  .lg-hide-items {
    .lg-prev {
      opacity: 0;
      @include translate3d(-10px, 0, 0);
    }

    .lg-next {
      opacity: 0;
      @include translate3d(10px, 0, 0);
    }

    .lg-toolbar {
      opacity: 0;
      @include translate3d(0, -10px, 0);
    }

    &.lg-hide-sub-html {
      .lg-sub-html {
        opacity: 0;
        @include translate3d(0, 20px, 0);
      }
    }
  }
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-outer;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  // For start/end transition
  @include transition(opacity 0.15s ease 0s);

  * {
    @include box-sizing(border-box);
  }

  &.lg-zoom-from-image {
    opacity: 1;
  }

  &.lg-visible {
    opacity: 1;
  }

  // Set transition speed and timing function
  &.lg-css3 {
    .lg-item:not(.lg-start-end-progress) {
      &.lg-prev-slide,
      &.lg-next-slide,
      &.lg-current {
        @include transition-duration(inherit !important);
        @include transition-timing-function(inherit !important);
      }
    }
  }

  // Remove transition while dragging
  &.lg-css3.lg-dragging {
    .lg-item {
      &.lg-prev-slide,
      &.lg-next-slide,
      &.lg-current {
        @include transition-duration(0s !important);
        opacity: 1;
      }
    }
  }

  // Set cursor grab while dragging
  &.lg-grab {
    img.lg-object {
      @include grab-cursor;
    }
  }

  &.lg-grabbing {
    img.lg-object {
      @include grabbing-cursor;
    }
  }

  .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
    outline: none;
  }

  .lg-inner {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    @include transition(opacity 0s);
    white-space: nowrap;
  }

  .lg-item {
    &:not(.lg-start-end-progress) {
      background: url("#{$lg-path-images}/loading.gif") no-repeat scroll center
        center transparent;
    }
    will-change: transform, opacity;
    display: none !important;
  }
  &.lg-css3 {
    .lg-prev-slide,
    .lg-current,
    .lg-next-slide {
      display: inline-block !important;
    }
  }
  &.lg-css {
    .lg-current {
      display: inline-block !important;
    }
  }

  .lg-item,
  .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;

    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  .lg-img-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    white-space: nowrap;
    font-size: 0;
  }

  .lg-item {
    &.lg-complete {
      background-image: none;
    }

    &.lg-current {
      z-index: $zindex-item;
    }
  }

  .lg-object {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: relative;
  }

  &.lg-show-after-load {
    .lg-item {
      .lg-object,
      .lg-video-play-button {
        opacity: 0;
        will-change: opacity;
        @include transition(opacity 0.15s ease 0s);
      }
      &.lg-zoom-from-image {
        .lg-object,
        .lg-video-play-button {
          opacity: 1;
        }
      }

      &.lg-complete {
        .lg-object,
        .lg-video-play-button {
          opacity: 1;
        }
      }
    }
  }

  // Hide title div if empty
  .lg-empty-html {
    display: none;
  }

  &[data-lg-slide-type="video"],
  &[data-lg-slide-type="iframe"] {
    .lg-download {
      opacity: $lg-toolbar-icon-disabled-opacity;
      pointer-events: none;
    }
  }

  .lg-first-slide {
    .lg-dummy-img {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  &.lg-components-open {
    &:not(.lg-zoomed) {
      .lg-components {
        @include translate3d(0, 0%, 0);
        opacity: 1;
      }
      .lg-sub-html {
        opacity: 1;
        transition: opacity 0.2s ease-out 0.15s;
      }
    }
  }
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-backdrop;
  background-color: $lg-theme-color;
  opacity: 0;
  will-change: auto;
  @include transition(opacity 333ms ease-in 0s);
  &.in {
    opacity: $backdrop-opacity;
  }
}

// Default slide animations. Should be placed at the bottom of the animation css
.lg-css3 {
  // Remove all transition effects
  &.lg-no-trans {
    .lg-prev-slide,
    .lg-next-slide,
    .lg-current {
      @include transitionCustom(none 0s ease 0s !important);
    }
  }

  &.lg-use-css3 {
    .lg-item {
      @include backface-visibility(hidden);
    }
  }

  // Fade mode
  &.lg-fade {
    .lg-item {
      opacity: 0;

      &.lg-current {
        opacity: 1;
      }

      // transition timing property and duration will be over written from javascript
      &.lg-prev-slide,
      &.lg-next-slide,
      &.lg-current {
        @include transitionCustom(opacity 0.1s ease 0s);
      }
    }
  }
  &.lg-use-css3 {
    .lg-item {
      &.lg-start-progress {
        @include transitionCustom(
          transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s
            //transform 1s cubic-bezier(0.34, 1.56, 0.64, 1) 0s,
        );
      }
      &.lg-start-end-progress {
        @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s);
      }
    }
  }

  &.lg-slide {
    &.lg-use-css3 {
      .lg-item {
        opacity: 0;

        &.lg-prev-slide {
          @include translate3d(-100%, 0, 0);
        }

        &.lg-next-slide {
          @include translate3d(100%, 0, 0);
        }

        &.lg-current {
          @include translate3d(0, 0, 0);
          opacity: 1;
        }

        // transition timing property and duration will be over written from javascript
        &.lg-prev-slide,
        &.lg-next-slide,
        &.lg-current {
          @include transitionCustom(
            transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
            opacity 0.1s ease 0s
          );
        }
      }
    }
  }
}

.lg-container {
  display: none;
  &.lg-show {
    display: block;
  }

  // Remove transition while dragging
  &.lg-dragging-vertical {
    .lg-backdrop {
      @include transition-duration(0s !important);
    }
    .lg-css3 {
      .lg-item {
        &.lg-current {
          @include transition-duration(0s !important);
          opacity: 1;
        }
      }
    }
  }
}

// Inline Gallery

.lg-inline {
  .lg-backdrop,
  .lg-outer {
    position: absolute;
  }
  .lg-backdrop {
    z-index: 1;
  }
  .lg-outer {
    z-index: 2;
  }
  .lg-maximize {
    &:after {
      content: "\e909";
    }
  }
}

// Components
.lg-components {
  @include translate3d(0, 100%, 0);
  will-change: transform;
  @include transitionCustom(transform 0.35s ease-out 0s);
  z-index: $zindex-components;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
